.chart-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  width: 100%;
  margin-bottom: 20px;
}

.chart-item {
  width: 50%;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
}

.select {
  width: 100%;
  border-radius: 20px;
}

h6 {
  font-weight: bold;
}